import React from 'react';
import { useField, FieldProps } from '@blockle/form';

import './input.css';

type Value = string;
type Props = {
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
} & FieldProps<Value>;

const Input = ({ name, value = '', required, placeholder, disabled = false }: Props) => {
  const { value: formValue, setValue, setTouched } = useField<Value>(name, {
    value,
    validate(value) {
      if (disabled) {
        return null;
      }

      if (required && !value.trim()) {
        return 'required';
      }

      return null;
    },
  });

  return (
    <div className={`Input ${disabled ? 'is-disabled' : ''}`}>
      <input
        type="text"
        className="Input-input"
        placeholder={placeholder}
        autoComplete="off"
        pattern="^[0-9 ]+$"
        minLength={4}
        value={formValue}
        onChange={event => setValue(event.target.value)}
        onFocus={setTouched}
        disabled={disabled}
      />
    </div>
  );
};

export default Input;
