import { Form } from '@blockle/form';
import { Link, useHistory } from '@blockle/router';
import { userActions } from '@playtwo/public';
import { BottomBar, TopBar } from 'components/Bar';
import Box from 'components/Box';
import { Button } from 'components/Button';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import FormError from 'components/Form/FormError';
import TelephoneInput from 'components/Form/TelephoneInput';
import Logo from 'components/Logo';
import { Heading, Text } from 'components/Typography';
import Warning from 'components/Warning';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './register-screen.css';

interface FormData {
  phonenumber: {
    code: string;
    tel: string;
  };
  termsOfUse: boolean;
}

const RegisterScreen = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const [responseError, setResponseError] = useState<string | null>(null);
  const submit = async (data: FormData) => {
    const phonenumber = `${data.phonenumber.code}${data.phonenumber.tel}`;

    setSending(true);
    setResponseError(null);

    try {
      await dispatch(userActions.authenticateWithPhoneNumber(phonenumber));

      // PhoneNumber accepted -> verify number
      push('/verify');
    } catch (error) {
      setResponseError(error.message);
    }

    setSending(false);
  };

  return (
    <>
      <TopBar right={<Logo size="small" />}>
        <Heading level="1" size="small">
          Register
        </Heading>
      </TopBar>

      <Form
        onSubmit={submit}
        className="RegisterScreen-form"
        render={() => (
          <>
            <Box grow gutter>
              <Text align="center" weight="semibold" gutter>
                Please confirm country code and enter your phone number
              </Text>

              <TelephoneInput name="phonenumber" required />

              {/* Form errors */}
              <FormError inputName="phonenumber" type="required">
                Please enter your phone number.
              </FormError>
              <FormError inputName="phonenumber" type="input">
                Sorry, that number isn’t right.
                <br />
                Please enter a valid phone number.
              </FormError>

              <FormError inputName="termsOfUse" type="required">
                Please accept the <Link to="/terms-of-use">Terms of Use</Link>.
              </FormError>

              <Warning open={!!responseError}>{responseError}</Warning>
            </Box>

            <BottomBar>
              <Box flex>
                <Box flex center className="RegisterScreen-termsOfUse">
                  <Checkbox name="termsOfUse" required />
                </Box>
                <Text size="small" weight="semibold">
                  By signing up you agree to the <Link to="/terms-of-use">Terms of Use</Link>,{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>, and{' '}
                  <Link to="/rules">Rules</Link>.
                </Text>
              </Box>
              <Button type="submit" disabled={sending}>
                Join
              </Button>
            </BottomBar>
          </>
        )}
      />
    </>
  );
};

export default RegisterScreen;
