import React from 'react';

import Paper from 'components/Paper';
import { Heading, Text } from 'components/Typography';
import { Button } from 'components/Button';
import Box from 'components/Box';

function reload() {
  window.location.reload();
}

const ErrorScreen = () => (
  <Paper open fit>
    <Box flex column gutter grow center>
      <Heading color="light" level="2" size="large">
        Oops
      </Heading>
      <Text color="light" align="center">
        Something went wrong, Please restart the app.
      </Text>
      <Button inline onClick={reload}>
        Restart
      </Button>
    </Box>
  </Paper>
);

export default ErrorScreen;
