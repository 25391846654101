import { Reducer, AnyAction } from 'redux';

export interface Country {
  name: string;
  code: string;
  isoCode: string;
}

export interface State {
  [key: string]: Country;
}

export const initialState: State = {
  NL: {
    name: 'Netherlands',
    code: '+31',
    isoCode: 'NL',
  },
  CU: {
    name: 'Cuba',
    code: '+53',
    isoCode: 'CU',
  },
  EG: {
    name: 'Egypt',
    code: '+20',
    isoCode: 'EG',
  },
  AQ: {
    name: 'Antarctica',
    code: '+672',
    isoCode: 'AQ',
  },
  TEST: {
    name: 'TEST',
    code: 'TEST',
    isoCode: 'TEST',
  },
};

export const countries: Reducer<State, AnyAction> = (state = initialState, action): State => {
  switch (action.type) {
    default:
      return state;
  }
};
