import React from 'react';
import cx from 'classnames';

import './bottom-bar.css';

type Props = {
  children: React.ReactNode;
  className?: string;
  shadow?: boolean;
};

export const BottomBar = ({ children, className, shadow }: Props) => (
  <footer className={cx('BottomBar', shadow && 'has-shadow', className)}>{children}</footer>
);

export default BottomBar;
