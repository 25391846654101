import React from 'react';
import cx from 'classnames';

import Icon, { IconNames } from 'components/Icon';

import './CircleIcon.css';

type Props = {
  className?: string;
  label: string;
  name: IconNames;
  variant?: 'default' | 'correct' | 'incorrect' | 'disabled';
  size?: 'small' | 'medium' | 'large';
};

const CircleIcon = ({ className, label, name, size = 'medium', variant = 'default' }: Props) => (
  <div
    data-testid="circle-icon"
    className={cx('CircleIcon', className, `size-${size}`, `variant-${variant}`)}
  >
    <Icon
      color={variant === 'default' ? 'accent' : 'light'}
      name={name}
      label={label}
      size={size === 'small' ? 'tiny' : size}
    />
  </div>
);

export default CircleIcon;
