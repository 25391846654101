import React, { useState, useEffect } from 'react';

import './countdown.css';
import { getServerTime } from '@exmg/socket';

interface Props {
  endDate: Date;
}

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const STROKE_DASHARRAY = 750;

/**
 * Pad string
 *
 * @param input
 * @param length
 * @param padString
 */
const pad = (input: string | number, length: number, padString = '0') => {
  if (String(input).length >= length) {
    return input;
  }

  return `${padString.repeat(length - String(input).length)}${input}`;
};

/**
 * Returns an object with days, hours, minutes and seconds left till end date
 *
 * @param endDate
 */
const getTimeDiff = (endDate: Date) => {
  const now = getServerTime();
  let diff = (endDate.getTime() - now) / 1000;

  const timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  // Clear countdown when date is reached
  if (diff <= 0) {
    return timeLeft;
  }

  if (diff >= DAY) {
    timeLeft.days = Math.floor(diff / DAY);
    diff -= timeLeft.days * DAY;
  }

  if (diff >= HOUR) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / HOUR);
    diff -= timeLeft.hours * HOUR;
  }

  if (diff >= MINUTE) {
    timeLeft.minutes = Math.floor(diff / MINUTE);
    diff -= timeLeft.minutes * MINUTE;
  }

  timeLeft.seconds = Math.floor(diff);

  return timeLeft;
};

/**
 * Get next position for for svg ring.
 * Offset is based on a day (84600 seconds).
 *
 * @param endDate
 */
const getRingPosition = (endDate: Date) => {
  const now = getServerTime();
  const diff = ((endDate.getTime() - now) / 1000) % DAY;

  return STROKE_DASHARRAY - STROKE_DASHARRAY * (diff / DAY);
};

const Countdown = ({ endDate: date }: Props) => {
  const [state, setState] = useState({
    ringPosition: getRingPosition(date),
    ...getTimeDiff(date),
  });
  const { days, hours, minutes, seconds, ringPosition } = state;
  const hoursCountdownEnabled = days === 0;

  useEffect(() => {
    const timer = setInterval(
      () => {
        setState({
          ringPosition: getRingPosition(date),
          ...getTimeDiff(date),
        });
      },
      state.days ? 60000 : 1000,
    );

    return () => clearInterval(timer);
  }, [date.getTime(), state.days]);

  if (!days && !hours && !minutes && !seconds) return null;

  return (
    <div className="countdown">
      <div className="countdown-content">
        <div className="countdown-label">Next Game</div>
        {hoursCountdownEnabled ? (
          <div className="countdown-time">
            <div className="countdown-time-group" data-testid="countdown-time-hours">
              <div className="countdown-count">{pad(hours, 2)}</div>
              <div className="countdown-time-label">Hours</div>
            </div>
            <div className="countdown-divider">:</div>
            <div className="countdown-time-group" data-testid="countdown-time-minutes">
              <div className="countdown-count">{pad(minutes, 2)}</div>
              <div className="countdown-time-label">Min</div>
            </div>
            <div className="countdown-divider">:</div>
            <div className="countdown-time-group" data-testid="countdown-time-seconds">
              <div className="countdown-count">{pad(seconds, 2)}</div>
              <div className="countdown-time-label">Sec</div>
            </div>
          </div>
        ) : (
          <div className="countdown-time-group" data-testid="countdown-time-days">
            <div className="countdown-day-count">{days}</div>
            <div className="countdown-time-label">Days</div>
          </div>
        )}
      </div>
      <div className={`countdown-ring ${hoursCountdownEnabled ? 'is-enabled' : ''}`}>
        {hoursCountdownEnabled && (
          <svg
            data-testid="countdown-ring"
            viewBox="0 0 244 244"
            preserveAspectRatio="xMinYMin meet"
          >
            <defs>
              <linearGradient
                id="gradient"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0"
                gradientTransform="rotate(46)"
              >
                <stop offset="0%" stopColor="#8f00aa" />
                <stop offset="100%" stopColor="#ff5793" />
              </linearGradient>
            </defs>
            <circle
              stroke="#fff"
              fill="transparent"
              strokeWidth="5"
              strokeLinecap="round"
              strokeDasharray={STROKE_DASHARRAY}
              r="119.5"
              cx="122"
              cy="122"
            />
            <circle
              className="countdown-ring-movement"
              stroke="url(#gradient)"
              fill="transparent"
              strokeWidth="5"
              strokeLinecap="round"
              strokeDasharray={STROKE_DASHARRAY}
              style={{ strokeDashoffset: ringPosition }}
              r="119.5"
              cx="122"
              cy="122"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default Countdown;
