import React from 'react';

import VerifyPage from './Verify';
import RouteTransition from 'components/RouteTransition';
import Paper from 'components/Paper';
import RegisterPage from './Register';
import TextContentScreen from 'screens/TextContent';

type Props = {
  open: boolean;
};

const AuthenticateScreen = ({ open }: Props) => (
  <Paper open={open} fit>
    <RegisterPage />

    <RouteTransition path="/verify">
      <VerifyPage />
    </RouteTransition>

    <RouteTransition path="/rules">
      <TextContentScreen contentKey="rules" icon="help" />
    </RouteTransition>
  </Paper>
);

export default AuthenticateScreen;
