import React from 'react';

type Props = {
  children: React.ReactNode;
  selected: boolean;
  onClick: () => void;
};

const TabListItem = ({ children, selected, onClick }: Props) => {
  return (
    <div className={`Tabs-list-item ${selected ? 'is-selected' : ''}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default TabListItem;
