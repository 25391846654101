import React from 'react';

import './warning.css';
import Paper from 'components/Paper';
import CircleIcon from 'components/CircleIcon';
import { Text } from 'components/Typography';

type Props = {
  children: React.ReactNode;
  open: boolean;
};

const Warning = ({ children, open }: Props) => (
  <Paper open={open} className="Warning" effect="slideUp">
    <CircleIcon name="warning" label="Warn" variant="disabled" size="small" />
    <Text size="small" weight="semibold" color="accent">
      {children}
    </Text>
  </Paper>
);

export default Warning;
