import { Link } from '@blockle/router';
import { userActions } from '@playtwo/public';
import { BottomBar, TopBar } from 'components/Bar';
import Box from 'components/Box';
import { Button, IconButton, MenuButton } from 'components/Button';
import Dialog from 'components/Dialog';
import { Heading } from 'components/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './drawer-content.css';

const DrawerContent = () => {
  const dispatch = useDispatch();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [sending, setSending] = useState(false);
  const logout = async () => {
    setSending(true);
    await dispatch(userActions.logout());
    setSending(false);
  };
  const closeDialog = () => setShowLogoutDialog(false);

  return (
    <>
      <TopBar>
        <Heading level="1">Menu</Heading>
      </TopBar>

      <Box grow scroll>
        <MenuButton to="/how-to-play" icon="help" label="How to play">
          How To Play
        </MenuButton>
        <MenuButton to="/profile" icon="profile" label="My profile">
          My profile
        </MenuButton>
        <MenuButton to="/faq" icon="faq" label="FAQ">
          FAQs
        </MenuButton>
        <MenuButton to="/help" icon="text-balloon" label="Get help">
          Get Help
        </MenuButton>
        <MenuButton to="/cash-out" icon="cashout" label="Balance and cashout">
          Balance &amp; Cashout
        </MenuButton>
        <MenuButton to="/data-policy" icon="data" label="Settings">
          Data Policy
        </MenuButton>
        <MenuButton to="/settings" icon="settings" label="Settings">
          Settings
        </MenuButton>
        <MenuButton icon="logout" label="Logout" onClick={() => setShowLogoutDialog(true)}>
          Logout
        </MenuButton>

        {__DEV__ && (
          <>
            <Heading level="3" size="small" align="left">
              Dev
            </Heading>
            <MenuButton to="/style-guide" icon="settings" label="Style guide">
              Style Guide
            </MenuButton>
            <MenuButton to="/translation-guide" icon="settings" label="Style guide">
              Translation Guide
            </MenuButton>
          </>
        )}
      </Box>

      <BottomBar className="DrawerContent-footer">
        <Box flex spaceBetween>
          <IconButton name="twitter" label="Twitter" color="light" iconSize="small" />
          <IconButton name="facebook" label="Facebook" color="light" iconSize="small" />
          <IconButton name="instagram" label="Instagram" color="light" iconSize="small" />
        </Box>

        <Box flex center className="DrawerContent-footer-links">
          <Link to="/privacy">Privacy</Link>
          <span>|</span>
          <Link to="terms">Terms</Link>
          <span>|</span>
          <Link to="/rules">Rules</Link>
        </Box>
      </BottomBar>

      <Dialog open={showLogoutDialog} onRequestClose={closeDialog}>
        <Heading level="2">Do you reallyyyy want to logout?</Heading>

        <Box flex>
          <Button flat onClick={closeDialog}>
            Cancel
          </Button>
          <Button flat secondary onClick={logout} disabled={sending}>
            Logout
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default React.memo(DrawerContent);
