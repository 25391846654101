import React from 'react';
import { Link, useHistory } from '@blockle/router';

import { Heading, Text } from 'components/Typography';
import { IconButton } from 'components/Button';
import { TopBar } from 'components/Bar';
import Box from 'components/Box';
import Logo from 'components/Logo';

const NotFoundScreen = () => {
  const { goBack } = useHistory();

  return (
    <>
      <TopBar
        left={<IconButton onClick={goBack} name="menu" label="Back" />}
        right={<Logo size="small" />}
      >
        <Heading level="1" size="small">
          Welcome
        </Heading>
      </TopBar>
      <Box grow scroll flex column center>
        <Text align="center">
          404 <Link to="/">Get me out of here</Link>
        </Text>
      </Box>
    </>
  );
};

export default NotFoundScreen;
