import { Form } from '@blockle/form';
import { useHistory } from '@blockle/router';
import { userActions, userSelectors } from '@playtwo/public';
import { BottomBar, TopBar } from 'components/Bar';
import Box from 'components/Box';
import { Button, IconButton } from 'components/Button';
import Input from 'components/Form/Input';
import Layout from 'components/Layout';
import Logo from 'components/Logo';
import { Heading, Text } from 'components/Typography';
import Warning from 'components/Warning';
import { useGoBack } from 'hooks';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import './verify-screen.css';

type FormData = {
  code: string;
};

const VerifyScreen = () => {
  const [sending, setSending] = useState(false);
  const { replace } = useHistory();
  const goBack = useGoBack();
  const dispatch = useDispatch();
  const isVerificationRestricted = useSelector((state: AppState) =>
    userSelectors.isVerificationRestricted(state),
  );
  const phoneNumber = useSelector((state: AppState) => state.userAccount.phoneNumber);
  const [responseError, setResponseError] = useState<string | null>(null);
  const submit = async (values: FormData) => {
    setSending(true);
    setResponseError(null);

    try {
      await dispatch(userActions.authenticateWithCode(values.code));
      replace('/');
    } catch (error) {
      setResponseError(error.message);
    }

    setSending(false);
  };

  return (
    <Layout>
      <TopBar
        left={<IconButton onClick={goBack} label="Back" name="arrow-back" />}
        right={<Logo size="small" />}
      >
        <Heading level="1" size="small">
          Verify
        </Heading>
      </TopBar>

      <Form
        onSubmit={submit}
        className="VerifyScreen-form"
        render={() => (
          <>
            <Box grow scroll gutter>
              <Text align="center" weight="semibold" gutter>
                Enter the verification number we just sent to {phoneNumber}
              </Text>
              <Input name="code" placeholder="Verification number" required />

              <Warning open={!!responseError}>{responseError}</Warning>
            </Box>

            <BottomBar>
              <Button type="submit" disabled={sending}>
                Verify
              </Button>
              <Button flat onClick={goBack} disabled={isVerificationRestricted}>
                I didn&apos;t get a code
              </Button>
            </BottomBar>
          </>
        )}
      />
    </Layout>
  );
};

export default VerifyScreen;
