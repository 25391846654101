import React from 'react';

import './flag.css';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getCountry } from 'selectors/countries';

type Props = {
  code: string;
};

const Flag = ({ code }: Props) => {
  const country = useSelector((state: AppState) => getCountry(state, code));

  if (!country) {
    return null;
  }

  return (
    <img src={`flags/${country.isoCode.toLowerCase()}.png`} className="Flag" alt={country.name} />
  );
};

export default Flag;
