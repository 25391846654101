import React from 'react';
import { useSelector } from 'react-redux';
import { textContentSelectors, textContentActions } from '@playtwo/public';

import './text-content-screen.css';
import { AppState } from 'reducers';
import { useThunkAction, useGoBack } from 'hooks';
import { TopBar } from 'components/Bar';
import { IconButton } from 'components/Button';
import { Heading, Text } from 'components/Typography';
import Box from 'components/Box';
import CircleIcon from 'components/CircleIcon';
import Layout from 'components/Layout';
import { IconNames } from 'components/Icon';

type Props = {
  contentKey: string;
  icon: IconNames;
};

const onCurrentDomainRegExp = new RegExp(`^https?://${location.hostname}`);

const textContentClickHandler = (event: React.SyntheticEvent<HTMLElement, MouseEvent>) => {
  const target = event.target as HTMLElement;
  const link = target.closest('a');

  if (!link) {
    return;
  }

  const href = link.getAttribute('href');

  if (href && /https?:/.test(href) && !onCurrentDomainRegExp.test(href)) {
    event.stopPropagation();
    event.preventDefault();

    // TODO When running in a bridge mode, open trough bridge.
    window.open(href);
  }
};

const TextContentScreen = ({ contentKey, icon }: Props) => {
  const isTerms = contentKey === 'terms';
  const goBack = useGoBack();
  const content = useSelector((state: AppState) =>
    isTerms
      ? textContentSelectors.getTerms(state)
      : textContentSelectors.getByKey(state, contentKey),
  );
  const getPageText = () =>
    isTerms ? textContentActions.getTerms() : textContentActions.getTextContent(contentKey);
  const { error } = useThunkAction(getPageText, [contentKey]);

  return (
    <Layout anglePosition="top">
      <TopBar left={<IconButton onClick={goBack} name="arrow-back" label="Back" />}>
        <Heading level="1" size="small">
          {content && content.title}
        </Heading>
      </TopBar>

      {error && (
        <Box flex center grow gutter>
          <Text>Unfortunately we cannot find the content you requested.</Text>
        </Box>
      )}

      {content && (
        <>
          <Box flex center>
            <CircleIcon size="large" name={icon} label={content.title} />
          </Box>
          <Box grow scroll gutter className="TextContentScreen">
            <div
              onClick={textContentClickHandler}
              dangerouslySetInnerHTML={{ __html: content.htmlContentBody }}
            />
          </Box>
        </>
      )}
    </Layout>
  );
};

export default TextContentScreen;
