import React from 'react';
import cx from 'classnames';

import './text.css';

type Props = {
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode;
  color?: undefined | 'light' | 'accent';
  gutter?: boolean;
  size?: 'small' | 'medium' | 'large';
  weight?: 'normal' | 'semibold' | 'bold';
};

const Text = ({
  align = 'left',
  children,
  color,
  gutter,
  size = 'medium',
  weight = 'normal',
}: Props) => (
  <p
    className={cx(
      'Text',
      `align-${align}`,
      `size-${size}`,
      `weight-${weight}`,
      color && `color-${color}`,
      gutter && 'has-gutter',
    )}
  >
    {children}
  </p>
);

export default Text;
