import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

if (!__TEST__) {
  i18next.use(XHR);
}

i18next.use(initReactI18next).init({
  debug: false,
  saveMissing: false,
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  ns: ['general'],
  defaultNS: 'general',
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (value instanceof Date) {
        switch (format) {
          case 'long':
            const options = {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            };
            return new Intl.DateTimeFormat(lng, options).format(value);
          default:
            return new Intl.DateTimeFormat(lng).format(value);
        }
      }
      return value;
    },
  },
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
});

export default i18next;
