import { createPlayTwo } from '@playtwo/core';
import { connectPlatform, platformReducers } from '@playtwo/platform';
import { connectPublic, publicHandler, publicReducers } from '@playtwo/public';
// tslint:disable-next-line:no-duplicate-imports
import * as reducers from 'reducers';
import { AppState } from 'reducers';

const playtwo = createPlayTwo({
  name: 'playtwo-reference-client',
  endpoint: 'localhost:4000',
  secure: false,
});

// Register message handlers "aka handle event received from websocket"
playtwo.setMessageHandlers([publicHandler]);

const { store, persistor } = playtwo.createStore<AppState, any, undefined, undefined>({
  ...reducers,
  ...platformReducers,
  ...publicReducers,
});

connectPlatform(store);
connectPublic(store);

export { store, persistor };
