/**
 * Position bar element underneath selected item
 *
 * @param listRef
 * @param barRef
 */
export const updateBarTransform = (
  listRef: React.RefObject<HTMLDivElement>,
  barRef: React.RefObject<HTMLDivElement>,
) => {
  if (!listRef.current || !barRef.current) {
    return;
  }

  const selected = listRef.current.querySelector<HTMLElement>('.is-selected');

  if (!selected) {
    return;
  }

  const { left: listLeft } = listRef.current.getBoundingClientRect();
  const { left, width } = selected.getBoundingClientRect();
  const scrollLeft = listRef.current.scrollLeft;

  const x = left - listLeft + scrollLeft;
  const scale = width / 100;

  barRef.current.style.transform = `translateX(${x}px) scaleX(${scale})`;
};
