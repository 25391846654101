import React from 'react';
import { Route } from '@blockle/router';
import Paper, { PaperEffectNames } from 'components/Paper';

type Params = { [key: string]: string } | undefined;

type Props = {
  children?: React.ReactNode;
  render?: (params: Params) => React.ReactNode;
  exact?: boolean;
  notFound?: boolean;
  path?: string | string[];
  effect?: PaperEffectNames;
};

const RouteTransition = ({
  children,
  effect = 'slideUp',
  exact,
  notFound,
  path,
  render,
}: Props) => (
  <Route
    exact={exact}
    noMatch={notFound}
    path={path}
    // tslint:disable-next-line jsx-no-lambda
    render={(match, params) => (
      <Paper open={match} fit effect={effect}>
        {render ? render(params) : children}
      </Paper>
    )}
  />
);

export default RouteTransition;
