import React, { useState } from 'react';

import './leaderboard.css';
import Box from 'components/Box';
import { Heading, Text } from 'components/Typography';
import { Button } from 'components/Button';
import Dialog from 'components/Dialog';

type Props = {
  name: string;
};

const LeaderboardScreen = ({ name }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Box flex column grow scroll gutter center className="LeaderboardScreen-container">
        <Box flex column center grow>
          <Heading level="3" size="large">
            {name} leaderboard is empty
          </Heading>
          <Text align="center">Complete your profile to access the different leaderboards</Text>
          <Button inline onClick={() => setDialogOpen(true)}>
            Edit Profile
          </Button>
        </Box>
      </Box>

      <Dialog open={dialogOpen} onRequestClose={() => setDialogOpen(false)}>
        <Heading level="2" size="small">
          Enter your age to participate in the {name} leaderboard
        </Heading>
        <Text align="center">-- Age --</Text>
        <Box flex center>
          <Button inline flat onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button inline flat secondary onClick={() => setDialogOpen(false)}>
            Save
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default LeaderboardScreen;
