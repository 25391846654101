import { useHistory } from '@blockle/router';

/**
 * History back,
 *
 * @param fallbackRoute string
 */
export const useGoBack = (fallbackRoute = '/') => {
  const { goBack, push } = useHistory();
  const referrer = document.referrer;

  if (!referrer || referrer.indexOf(location.host) === -1) {
    return () => push(fallbackRoute);
  }

  return goBack;
};
