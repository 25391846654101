import React, { useRef, useState, useCallback } from 'react';
import { useField, FieldProps } from '@blockle/form';
import { useSwipeable } from 'react-swipeable';

import './switch.css';

type EventTypes =
  | React.TouchEvent<HTMLElement>
  | React.MouseEvent<HTMLElement>
  | TouchEvent
  | MouseEvent;

interface Position {
  x: number;
  y: number;
}

type Value = boolean;

type Props = FieldProps<Value> & {
  name: string;
  checked?: Value;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
};

// const getPosition = (event: EventTypes): Position => {
//   let typedEvent: { pageX: number; pageY: number } = event as React.MouseEvent<HTMLElement>;
//   if ((event as React.TouchEvent<HTMLElement>).touches) {
//     typedEvent = (event as React.TouchEvent<HTMLElement>).touches[0];
//   }
//   return { x: typedEvent.pageX, y: typedEvent.pageY };
// };

const getPosition = (event: TouchEvent | MouseEvent): { x: number; y: number } => {
  if ('touches' in event) {
    return {
      x: event.touches[0].pageX,
      y: event.touches[0].pageY,
    };
  }

  return { x: event.pageX, y: event.pageY };
};

const Switch = ({ name, checked, onChange, disabled = false }: Props) => {
  const thumb = useRef<HTMLDivElement>(null);
  const track = useRef<HTMLDivElement>(null);
  const color = useRef<HTMLDivElement>(null);

  const [isActive, setIsActive] = useState(false);

  const { value, setValue, setTouched } = useField<Value>(name, {
    value: !!checked,
    validate() {
      return null;
    },
  });

  const movement = useRef(false);
  const moveX = useRef(0);
  const refValue = useRef(value);
  refValue.current = value;
  const startX = useRef(0);
  const trackWidth = useRef(0);

  const setChecked = (newValue: boolean) => {
    if (newValue !== refValue.current && onChange) {
      onChange(newValue);
    }
    setValue(newValue);
  };

  const swipe = (value: boolean) => {
    if (disabled || isActive) {
      return;
    }
    setChecked(value);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => swipe(false),
    onSwipedRight: () => swipe(true),
  });

  const move = useCallback((event: EventTypes) => {
    event.stopPropagation();
    event.preventDefault();
    if (!thumb.current || !color.current) {
      return;
    }
    movement.current = true;
    const max = trackWidth.current;

    moveX.current = getPosition(event).x;
    const newPosition = moveX.current - startX.current;
    const addition = refValue.current ? max : 0;
    const position = addition + newPosition;

    const limitedPosition = position < 0 ? 0 : position < max ? position : max;
    const opacity = limitedPosition / max / -1 + 1;

    color.current.style.opacity = `${opacity}`;
    thumb.current.style.transform = `translateX(${limitedPosition}px)`;
  }, []);

  const stop = useCallback((event: EventTypes) => {
    if (!thumb.current || !color.current) {
      return;
    }
    setIsActive(false);

    const half = trackWidth.current / 2;
    const position = moveX.current - startX.current;
    const newPosition = (refValue.current ? trackWidth.current : 0) + position;

    if (movement.current) {
      if (refValue.current) {
        if (newPosition < half) {
          setChecked(!refValue.current);
        }
      } else {
        if (newPosition > half) {
          setChecked(!refValue.current);
        }
      }
    } else {
      setChecked(!refValue.current);
      movement.current = false;
    }
    event.stopPropagation();
    event.preventDefault();

    color.current.style.opacity = null;
    thumb.current.style.transform = '';

    document.removeEventListener('touchmove', move);
    document.removeEventListener('touchend', stop);
    document.removeEventListener('mousemove', move);
    document.removeEventListener('mouseup', stop);
  }, []);

  const start = (event: EventTypes) => {
    event.stopPropagation();
    event.preventDefault();
    if (disabled || !track.current || !thumb.current) {
      return;
    }
    setIsActive(true);

    const trackDetail = track.current.getBoundingClientRect();
    const thumbDetail = thumb.current;

    movement.current = false;
    startX.current = getPosition(event).x;

    trackWidth.current = Math.floor(trackDetail.width / 2 - thumbDetail.offsetLeft * 2);

    document.addEventListener('touchmove', move, { passive: false });
    document.addEventListener('touchend', stop);

    document.addEventListener('mousemove', move, { passive: false });
    document.addEventListener('mouseup', stop);
  };

  const toggle = () => {
    if (disabled || isActive) {
      return;
    }
    setChecked(!value);
  };

  return (
    <div
      className={`Switch ${value ? 'is-checked' : ''} ${disabled ? 'is-disabled' : ''}`}
      data-testid="switch"
      onClick={toggle}
      onFocus={setTouched}
      tabIndex={disabled ? undefined : 0}
      onKeyDown={event => {
        if (event.keyCode === 32) {
          // Prevent unwanted scrolling
          event.preventDefault();

          toggle();
        }
      }}
      {...handlers}
    >
      <div className="Switch-track-color" ref={color} />
      <div className="Switch-track" ref={track}>
        <div
          className="Switch-thumb"
          data-testid="switch-thumb"
          onMouseDown={start}
          onTouchStart={start}
          ref={thumb}
        />
      </div>
    </div>
  );
};

export default Switch;
