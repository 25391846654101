import { userSelectors } from '@playtwo/public';
import Box from 'components/Box';
import { Button } from 'components/Button';
import Dialog from 'components/Dialog';
import RemoveElement from 'components/RemoveElement';
import RouteTransition from 'components/RouteTransition';
import ServiceWorker from 'components/ServiceWorker';
import SocketStatusDialog from 'components/SocketStatusDialog';
import { Heading } from 'components/Typography';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import ApplicationScreen from 'screens/Application';
import AuthenticateScreen from 'screens/Authenticate';
import StyleGuideScreen from 'screens/StyleGuide';
import TextContentScreen from 'screens/TextContent';
import TranslationGuideScreen from 'screens/TranslationGuide';
import './app.css';

const App = () => {
  const [updateReady, setUpdateReady] = useState(false);
  const isAuthenticated = useSelector((state: AppState) => userSelectors.isLoggedIn(state));
  const isInitialized = useSelector((state: AppState) => userSelectors.isInitialized(state));

  return (
    <>
      <Box fit className="App">
        {/* Using open boolean so we can transition the screens (fadeIn/Out) */}
        <AuthenticateScreen open={!isAuthenticated} />
        <ApplicationScreen open={isAuthenticated} />

        <RouteTransition path="/terms-of-use">
          <TextContentScreen contentKey="terms" icon="help" />
        </RouteTransition>

        <RouteTransition path="/privacy-policy">
          <TextContentScreen contentKey="privacy-policy" icon="help" />
        </RouteTransition>

        {__DEV__ && (
          <>
            <RouteTransition path="/style-guide" exact effect="slideUp">
              <StyleGuideScreen />
            </RouteTransition>
            <RouteTransition path="/translation-guide" exact effect="slideUp">
              <TranslationGuideScreen />
            </RouteTransition>
          </>
        )}
      </Box>

      <ServiceWorker onUpdate={() => setUpdateReady(true)} />
      <SocketStatusDialog />

      <Dialog open={updateReady} onRequestClose={() => {}}>
        <Heading level="2" size="small">
          Update found
        </Heading>
        <Box flex center>
          <Button inline flat secondary onClick={() => location.reload()}>
            Update
          </Button>
        </Box>
      </Dialog>

      <RemoveElement target="#app-splash" when={isInitialized} duration={600} />
      <RemoveElement target="#app-splash .splash-logo" when={isInitialized} duration={400} />
    </>
  );
};

export default hot(App);
