// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useRef, useEffect, useState, HTMLAttributes, MutableRefObject } from 'react';
import * as Sentry from '@sentry/browser';

import './LiveryPlayer.css';
import loadDashjs from './dash';

type PlaybackState =
  | 'BUFFERING'
  | 'ENDED'
  | 'FAST_FORWARD'
  | 'PAUSED'
  | 'PLAYING'
  | 'REWIND'
  | 'SEEKING'
  | 'SLOW_MO'
  | undefined;
interface LiveryEventTypes extends Event {
  detail?: LiveryEventDetails;
}
type LiveryEventDetails = {
  playbackState: PlaybackState;
};

// TODO: remove when we get types from livery LLS-900
/*eslint-disable */
declare global {
  namespace JSX {
    export interface IntrinsicElements {
      'live-sdk': LiveSDK & HTMLAttributes<any>;
      'live-player': LivePlayer & HTMLAttributes<any>;
    }
    interface LiveSDK {
      config: string;
    }
    interface LivePlayer {
      ref?: MutableRefObject<HTMLElement | undefined>;
      autoplay?: boolean;
      'autoplay-muted'?: boolean;
      controls?: boolean;
      loop?: boolean;
      muted?: boolean;
      'persist-muted'?: boolean;
      poster?: string;
      preload?: 'null' | 'none' | 'metadata' | 'full';
    }
  }
}
/*eslint-enable */

const LiveryPlayer = () => {
  loadDashjs(() => {
    import(/* webpackChunkName: 'livery' */ '@exmg/media-player-live-elements');
  });

  const player = useRef<HTMLElement>();
  const [isHidden, setIsHidden] = useState(false);

  const error = (event: LiveryEventTypes) => {
    // Log livery error to sentry
    Sentry.captureException(event);

    setIsHidden(false);
  };

  const playbackchange = (event: LiveryEventTypes) => {
    const playbackState = event.detail && event.detail.playbackState;

    if (playbackState == 'BUFFERING' || playbackState == 'ENDED' || playbackState == 'PAUSED') {
      return;
    }

    setIsHidden(true);
  };

  useEffect(() => {
    if (!player.current) {
      return;
    }

    player.current.addEventListener('error', error);
    player.current.addEventListener('playbackchange', playbackchange);

    return () => {
      if (!player.current) {
        return;
      }

      player.current.removeEventListener('error', error);
      player.current.removeEventListener('playbackchange', playbackchange);
    };
  }, []);

  const config =
    'https://cdn.playtotv.com/video-encoder-dev/remoteconfigs/5c52edb53e930320967a5d55.json';

  return (
    <div className={`LiveryPlayer ${!isHidden ? 'is-hidden' : ''}`} data-testid="livery-player">
      <live-sdk config={config}></live-sdk>
      <live-player ref={player} autoplay-muted persist-muted>
        <source src="https://exmachina-ull-demo.akamaized.net/cmaf/live/664379/5c52edb53e930320967a5d55-TESTING/out.mpd" />
        <source src="https://exmachina-ull-demo.akamaized.net/cmaf/backup/664379/5c52edb53e930320967a5d55-TESTING/out.mpd" />
        <source src="https://exmachina-ull-demo.akamaized.net/cmaf/live/664379/5c52edb53e930320967a5d55-TESTING/master.m3u8" />
        <source src="https://exmachina-ull-demo.akamaized.net/cmaf/backup/664379/5c52edb53e930320967a5d55-TESTING/master.m3u8" />
      </live-player>
    </div>
  );
};

export default LiveryPlayer;
