import { Form } from '@blockle/form';
import { Link } from '@blockle/router';
import { userActions } from '@playtwo/public';
import { BottomBar, TopBar } from 'components/Bar';
import Box from 'components/Box';
import { Button } from 'components/Button';
import Checkbox from 'components/Form/Checkbox';
import FormError from 'components/Form/FormError';
import Layout from 'components/Layout';
import Logo from 'components/Logo';
import Paper from 'components/Paper';
import { Heading, Text } from 'components/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './request-profile-info-screen.css';

type Props = {
  open: boolean;
};

const TermsScreen = ({ open }: Props) => {
  const [sending, setSending] = useState(false);
  const dispatch = useDispatch();

  const submit = async () => {
    setSending(true);

    try {
      await dispatch(userActions.acceptTerms());
    } catch (error) {
      console.log(error);
    }

    setSending(false);
  };

  return (
    <Paper open={open} fit effect="fade">
      <Layout>
        <TopBar right={<Logo size="small" />}>
          <Heading level="1" size="small">
            Verification
          </Heading>
        </TopBar>

        <Form
          onSubmit={submit}
          className="TermsScreen-form"
          render={() => (
            <>
              <Box grow gutter center>
                <Text align="center" weight="bold" gutter>
                  Welcome back!
                </Text>

                <Text align="center" weight="bold" gutter>
                  We made some updates to our Terms & Services and Privacy Policy.
                </Text>

                <br />
                <br />

                <Text align="center" weight="bold" gutter>
                  Please review and accept the changes to play again.
                </Text>

                <div className="TermsScreen-checkbox">
                  <Checkbox name="terms" required />
                  <Text>
                    I agree to the <Link to="/terms-of-use">Terms of Use</Link>
                  </Text>
                </div>
                <div className="TermsScreen-checkbox">
                  <Checkbox name="policy" required />
                  <Text>
                    I agree to the <Link to="/privacy-policy">Privacy Policy</Link>
                  </Text>
                </div>

                <FormError inputName="terms" type="required">
                  Please accept the <Link to="/terms-of-use">Terms of Use</Link>
                </FormError>
                <FormError inputName="policy" type="required">
                  Please accept the <Link to="/privacy-policy">Privacy Policy</Link>
                </FormError>
              </Box>

              <BottomBar>
                <Button type="submit" disabled={sending}>
                  Submit
                </Button>
                <Button flat to="/privacy-policy">
                  See data policy
                </Button>
              </BottomBar>
            </>
          )}
        />
      </Layout>
    </Paper>
  );
};

export default TermsScreen;
