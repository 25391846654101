import React, { useEffect } from 'react';

import { Workbox } from 'workbox-window';

type Props = {
  children?: React.ReactNode;
  onUpdate?: () => void;
  onWaiting?: () => void;
};

const ServiceWorker = ({ children = null, onUpdate, onWaiting }: Props) => {
  useEffect(() => {
    if (!('serviceWorker' in navigator) || module.hot) {
      return;
    }

    const wb = new Workbox('/sw.js');

    wb.addEventListener('activated', event => {
      if (event.isUpdate && onUpdate) {
        onUpdate();
      }
    });

    wb.addEventListener('waiting', () => {
      if (onWaiting) {
        onWaiting();
      }
    });

    wb.register();
  }, []);

  return <>{children}</>;
};

export default ServiceWorker;
