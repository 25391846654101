import React from 'react';
import cx from 'classnames';

import './avatar.css';
import Image from 'components/Image';
import Icon, { IconNames } from 'components/Icon';

type Props = {
  className?: string;
  icon?: IconNames;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  url: string;
};

const Avatar = ({ className, icon, onClick, size = 'medium', url }: Props) => (
  <div
    className={cx(
      'Avatar',
      `size-${size}`,
      icon && 'has-icon',
      onClick && 'is-clickable',
      className,
    )}
    data-testid="avatar"
    onClick={onClick}
    tabIndex={onClick ? 0 : undefined}
    onKeyUp={event => {
      // space | enter
      if (onClick && (event.keyCode === 32 || event.keyCode === 13)) {
        onClick();
      }
    }}
  >
    <Image src={url} fit="cover" label="Avatar" className="Avatar-image" />

    {icon && (
      <div className="Avatar-icon" onClick={onClick}>
        <Icon name={icon} color="light" label="" size="small" />
      </div>
    )}
  </div>
);

export default Avatar;
