const loadDashjs = (callback: () => void) => {
  if (window.dashjs) {
    callback();
    return;
  }
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://cdn.dashjs.org/v2.9.3/dash.all.min.js';
  script.id = 'dashScript';
  script.setAttribute('async', 'true');
  document.body.appendChild(script);

  function onLoad() {
    callback();

    script.removeEventListener('load', onLoad);
  }

  script.addEventListener('load', onLoad);
};

export default loadDashjs;
