import React, { useState, useLayoutEffect, useRef, useCallback, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

import './tabs.css';
import Tab, { Props as TabProps } from './Tab';
import TabListItem from './TabListItem';
import { updateBarTransform } from './helpers';

type TabsChild = React.ReactElement<TabProps> | React.ReactElement;
type Props = {
  children: TabsChild[];
};

const Tabs = ({ children }: Props) => {
  const [selectedIndex, setTabIndex] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);
  const barRef = useRef<HTMLDivElement>(null);
  const updateBar = useCallback(() => updateBarTransform(listRef, barRef), []);
  const childrenArray = React.Children.toArray(children);
  const handlers = useSwipeable({
    onSwipedLeft: () => setTabIndex(Math.min(selectedIndex + 1, childrenArray.length - 1)),
    onSwipedRight: () => selectedIndex && setTabIndex(selectedIndex - 1),
  });

  useEffect(() => {
    window.addEventListener('resize', updateBar);

    return () => window.removeEventListener('resize', updateBar);
  }, []);

  useLayoutEffect(updateBar, [selectedIndex]);

  return (
    <div className="Tabs">
      <div className="Tabs-list" ref={listRef}>
        {childrenArray.map((child, i) => {
          if (child.type !== Tab) {
            return child;
          }

          return (
            <TabListItem key={i} selected={i === selectedIndex} onClick={() => setTabIndex(i)}>
              {child.props.label}
            </TabListItem>
          );
        })}

        <div ref={barRef} className="Tabs-list-bar" />
      </div>

      <div className="Tabs-panels" {...handlers}>
        <div className="Tabs-panel">{childrenArray[selectedIndex]}</div>
      </div>
    </div>
  );
};

export default Tabs;
