import React from 'react';
import { useFieldState } from '@blockle/form';

import Warning from 'components/Warning';

type Props = {
  inputName: string;
  type: string;
  children: React.ReactNode;
};

const FormError = ({ inputName, type, children }: Props) => {
  const state = useFieldState(inputName);

  return (
    <Warning open={state.touched && state.invalid && state.validationMessage === type}>
      {children}
    </Warning>
  );
};

export default FormError;
