import React from 'react';
import { useSelector } from 'react-redux';

import './playercard.css';
import Avatar from 'components/Avatar';
import Icon, { IconNames } from 'components/Icon';
import { AppState } from 'reducers';

type Stats = {
  name: string;
  value: string;
  icon: IconNames;
};

const PlayerCard = () => {
  const { avatarUrl, displayName } = useSelector((state: AppState) => state.userProfile);

  const stats: Stats[] = [
    { name: 'balance', value: '140€', icon: 'coins-stack' },
    { name: 'highest score', value: '4/12', icon: 'trophy' },
  ]; // TODO: useSelector to get cash / player stats

  return (
    <div className="PlayerCard" data-testid="player-card">
      <div className="PlayerCard-column">
        <Avatar url={avatarUrl} />
        <p className="PlayerCard-player-info">{displayName}</p>
      </div>
      <div className="PlayerCard-column">
        {stats.map((stat, index) => (
          <div key={index} className="PlayerCard-row">
            <p className="PlayerCard-card-title">
              <Icon color="light" size="small" name={stat.icon} label={stat.name} />
              {stat.name}
            </p>
            <p className="PlayerCard-player-info">{stat.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayerCard;
