import React from 'react';
import cx from 'classnames';

import './paper.css';
import { useAnimationState } from 'hooks';

export type PaperEffectNames = 'fade' | 'slideUp';

type Props = {
  children: React.ReactNode;
  className?: string;
  effect?: PaperEffectNames;
  fit?: boolean;
  open: boolean;
};

const animationLeaveExpression = /^Paper-.*?Leave$/;

const Paper = ({ open, effect = 'fade', className, children, fit }: Props) => {
  const [state, close] = useAnimationState(open);

  if (!state.open) {
    return null;
  }

  // if fit, render to layer?

  return (
    <div
      className={cx(
        'Paper',
        state.leave && 'is-leave',
        className,
        `effect-${effect}`,
        fit && 'is-fit',
      )}
      onAnimationEnd={(event: React.AnimationEvent<HTMLDivElement>) => {
        if (animationLeaveExpression.test(event.animationName)) {
          close();
        }
      }}
    >
      {children}
    </div>
  );
};

export default Paper;
