import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useField, FieldProps } from '@blockle/form';

import './telephone-input.css';
import Flag from 'components/Flag';
import { Country } from 'reducers/countries';
import { AppState } from 'reducers';
import { getAllCountries } from 'selectors/countries';
import Icon from 'components/Icon';
import Dialog from 'components/Dialog';

type Value = {
  code: string;
  tel: string;
};

type Props = { required?: boolean } & FieldProps<Value>;

const TelephoneInput = ({ name, required }: Props) => {
  const countries: Country[] = useSelector((state: AppState) => getAllCountries(state));
  const { value, touched, invalid, setValue, setTouched } = useField<Value>(name, {
    value: { code: 'TEST', tel: '' }, // countryCode & localPhoneNumber
    validate(value: Value) {
      if (required && !value.tel.trim()) {
        return 'required';
      }

      if (value.tel.trim() && !/^[0-9\s]+$/.test(value.tel)) {
        return 'input';
      }

      return null;
    },
  });

  const [dialog, setDialog] = useState(false);

  const info = countries.find(({ code }) => code === value.code);

  if (!info) {
    return <>Failed to find country ({value.code}) in TelephoneInput</>;
  }

  return (
    <>
      <div className={`TelephoneInput ${touched && invalid ? 'is-invalid' : ''}`}>
        <div className="TelephoneInput-country-button" onClick={() => setDialog(true)}>
          <Flag code={info.isoCode} />
          <Icon label="Select country" name="dropdown" size="small" />
        </div>
        <div className="TelephoneInput-divider" />
        <span className="TelephoneInput-input-prefix">{info && info.code}</span>
        <input
          type="tel"
          className="TelephoneInput-input"
          placeholder="612345678"
          autoComplete="off"
          pattern="^[0-9 ]+$"
          minLength={6}
          value={value.tel}
          onChange={event => setValue({ ...value, tel: event.target.value })}
          onBlur={setTouched}
        />
      </div>

      <Dialog open={dialog} onRequestClose={() => setDialog(false)}>
        {countries.map(({ isoCode, code, name }) => (
          <div
            key={isoCode}
            className={`TelephoneInput-dialog-row ${code === value.code ? 'is-selected' : ''}`}
            onClick={() => {
              setValue({ ...value, code });
              setDialog(false);
            }}
          >
            <Flag code={isoCode} />
            <span className="TelephoneInput-dialog-row-name">{name}</span>
            <span>{code}</span>
          </div>
        ))}
      </Dialog>
    </>
  );
};

export default TelephoneInput;
