import React from 'react';
import cx from 'classnames';

import './icon-button.css';
import Icon, { IconNames } from 'components/Icon';
import ButtonLink, { LinkProps } from '../ButtonLink';

type Props = {
  disabled?: boolean;
  label: string;
  name: IconNames;
  onClick?: () => void;
  color?: 'light' | 'dark' | 'accent';
  iconSize?: 'tiny' | 'small' | 'medium' | 'large';
} & Partial<LinkProps>;

const IconButton = ({
  disabled,
  label,
  name,
  color = 'dark',
  iconSize = 'medium',
  ...props
}: Props) => (
  <ButtonLink
    className={cx('IconButton', disabled && 'is-disabled')}
    disabled={disabled}
    tabIndex={0}
    {...props}
  >
    <Icon color={color} name={name} label={label} size={iconSize} />
  </ButtonLink>
);

export default IconButton;
