import React, { useState } from 'react';
import cx from 'classnames';

import './image.css';

interface Props {
  className?: string;
  fit?: 'fill' | 'contain' | 'cover' | 'none';
  height?: number;
  label: string;
  src: string;
  width?: number;
}

const Image = ({ className, fit = 'none', height, label, src, width }: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <img
      data-testid="image"
      className={cx('Image', className, `fit-${fit}`, loaded && 'is-loaded')}
      src={src}
      onLoad={() => setLoaded(true)}
      alt={label}
      style={{
        width: width ? `${width}px` : undefined,
        height: height ? `${height}px` : undefined,
      }}
    />
  );
};

export default Image;
