import React from 'react';
import cx from 'classnames';

import './menu-button.css';
import ButtonLink, { LinkProps } from '../ButtonLink';
import Icon, { IconNames } from 'components/Icon';

interface Props extends LinkProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  icon: IconNames;
  label: string;
}

const MenuButton = ({ children, disabled, icon, label, ...props }: Props) => (
  <ButtonLink
    disabled={disabled}
    className={cx('MenuButton', disabled && 'is-disabled')}
    {...props}
  >
    <Icon color="light" name={icon} label={label} />

    {children}
  </ButtonLink>
);

export default MenuButton;
