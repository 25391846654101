import React from 'react';
import { Link } from '@blockle/router';
import Ripple from 'components/Ripple';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  crossOrigin?: string;
  disabled?: boolean;
  href?: string;
  hrefLang?: string;
  integrity?: string;
  media?: string;
  rel?: string;
  replace?: boolean;
  sizes?: string;
  to?: string;
  type?: string;
  tabIndex?: number;
};

const ButtonLink = ({ to, replace, children, ...props }: Props) => {
  if (to !== undefined) {
    return (
      <Link to={to} renderAs={<Ripple renderAs="a" href={to} />} replace={replace} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <Ripple renderAs="button" type="button" {...props}>
      {children}
    </Ripple>
  );
};

export default ButtonLink;
