import React from 'react';

import './heading.css';

type Props = {
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode;
  color?: 'light' | 'dark';
  level?: '1' | '2' | '3';
  size?: 'small' | 'medium' | 'large';
};

const Heading = ({
  align = 'center',
  children,
  color = 'dark',
  level = '1',
  size = 'medium',
}: Props) =>
  React.createElement(
    `h${level}`,
    {
      className: `Heading size-${size} align-${align} color-${color}`,
    },
    children,
  );

export default Heading;
