import React from 'react';
import cx from 'classnames';

import './button.css';
import ButtonLink from '../ButtonLink';

type Props = {
  children: React.ReactNode;
  to?: string;
  onClick?: () => void;
  form?: string; // Remove me
  type?: 'button' | 'submit';
  flat?: boolean;
  secondary?: boolean;
  inline?: boolean;
  disabled?: boolean;
};

const Button = ({
  children,
  flat,
  secondary,
  inline,
  disabled,
  type = 'button',
  ...props
}: Props) => {
  return (
    <ButtonLink
      className={cx(
        'Button',
        flat && 'is-flat',
        secondary && 'is-secondary',
        inline && 'is-inline',
        disabled && 'is-disabled',
      )}
      disabled={disabled}
      tabIndex={0}
      type={type}
      {...props}
    >
      {children}
    </ButtonLink>
  );
};

export default Button;
