import React from 'react';
import cx from 'classnames';

import './box.css';

type Props = {
  children: React.ReactNode;
  className?: string;
  center?: boolean;
  column?: boolean;
  fit?: boolean;
  flex?: boolean;
  grow?: boolean;
  gutter?: boolean;
  scroll?: boolean;
  spaceBetween?: boolean;
};

const Box = ({
  center,
  children,
  className,
  column,
  fit,
  flex,
  grow,
  gutter,
  scroll,
  spaceBetween,
}: Props) => (
  <div
    className={cx(
      'Box',
      className,
      fit && 'is-fit',
      flex && 'is-flex',
      column && 'is-column',
      scroll && 'is-scroll',
      gutter && 'has-gutter',
      center && 'is-centered',
      spaceBetween && 'has-space-between',
      grow && 'has-grow',
    )}
  >
    {children}
  </div>
);

export default Box;
