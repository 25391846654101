import { Form } from '@blockle/form';
import { userActions } from '@playtwo/public';
import { BottomBar, TopBar } from 'components/Bar';
import Box from 'components/Box';
import { Button } from 'components/Button';
import AvatarSelect from 'components/Form/AvatarSelect';
import FormError from 'components/Form/FormError';
import Input from 'components/Form/Input';
import Layout from 'components/Layout';
import Logo from 'components/Logo';
import Paper from 'components/Paper';
import { Heading, Text } from 'components/Typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './profile-info-screen.css';

type FormData = {
  avatarUrl: string;
  displayName: string;
};

type Props = {
  open: boolean;
};

const ProfileInfoScreen = ({ open }: Props) => {
  const [sending, setSending] = useState(false);
  const dispatch = useDispatch();

  const submit = async ({ displayName, avatarUrl }: FormData) => {
    setSending(true);

    try {
      await dispatch(userActions.setProfile({ displayName, avatarUrl }));
    } catch (error) {
      alert(error.message);
    }

    setSending(false);
  };

  return (
    <Paper open={open} fit effect="fade">
      <Layout>
        <TopBar right={<Logo size="small" />}>
          <Heading level="1" size="small">
            Profile
          </Heading>
        </TopBar>

        <Form
          onSubmit={submit}
          className="ProfileInfoScreen-form"
          render={() => (
            <>
              <Box grow gutter center>
                <Text align="center" weight="semibold" gutter>
                  You’re almost done!
                  <br />
                  Enter a username and a profile picture
                </Text>

                <Box flex center className="ProfileInfoScreen-avatar">
                  <AvatarSelect name="avatarUrl" required />
                </Box>

                <Input name="displayName" placeholder="Display name" required />

                <FormError inputName="displayName" type="required">
                  Sorry, that username is already taken.
                </FormError>
              </Box>

              <BottomBar>
                <Button type="submit" disabled={sending}>
                  Next
                </Button>
              </BottomBar>
            </>
          )}
        />
      </Layout>
    </Paper>
  );
};

export default ProfileInfoScreen;
