import { init } from '@sentry/browser';
import { AppShell } from 'components/App';
import React from 'react';
import ReactDOM from 'react-dom';
import { setConfig } from 'react-hot-loader';
import './reset.css';
import './splash.css';

if (process.env.NODE_ENV === 'production') {
  init({ dsn: 'https://15c5d698f410490bb1795d4323bab209@sentry.io/1524066' });
}

setConfig({
  ignoreSFC: true,
  pureRender: true,
});

const mountNode = document.getElementById('root');

ReactDOM.render(<AppShell />, mountNode);

// Scroll to active input whenever viewport changes size
// This is most useful on mobile devices, input is not (always) visible after opening the virtual keyboard.
window.addEventListener('resize', () => {
  if (document.activeElement && document.activeElement.nodeName === 'INPUT') {
    document.activeElement.scrollIntoView({ block: 'center' });
  }
});

// Disable overflow scrolling on iOS devices.
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

if (isIOS) {
  document.documentElement.className = 'ios';
}
