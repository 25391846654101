import React from 'react';
import { useTranslation, Translation, Trans } from 'react-i18next';
// import { TranslationFunction } from 'i18next';
// import { NamespacesConsumer, Trans } from 'react-i18next';

interface Props {
  children?: React.ReactNode;
  count?: number;
  date?: Date;
  i18nKey: string;
  ns?: string;
  options?: {};
}

const Translate = ({ children, count, date, i18nKey, options, ns }: Props) => {
  const [t, i18n] = useTranslation();

  return (
    <Translation ns={[ns!]}>
      {() => {
        const translationOptions = Object.assign(
          options,
          count !== undefined && { count },
          date && { date },
        );

        if (children && typeof children !== 'string' && i18nKey) {
          return <Trans i18nKey={i18nKey}>{children}</Trans>;
        }

        const tkey = [];
        if (i18nKey) {
          tkey.push(i18nKey);
        }

        if (children && typeof children === 'string') {
          tkey.push(children);
        }

        return t(tkey, translationOptions);
      }}
    </Translation>
  );
};

Translate.defaultProps = {
  options: {},
  ns: 'general',
};

export default Translate;
