import React, { useState } from 'react';
import { Form } from '@blockle/form';

import Layout from 'components/Layout';
import { TopBar } from 'components/Bar';
import { Heading, Text } from 'components/Typography';
import { IconButton, Button } from 'components/Button';
import { useGoBack } from 'hooks';
import Box from 'components/Box';
import CircleIcon from 'components/CircleIcon';
import Dialog from 'components/Dialog';
import Input from 'components/Form/Input';

const DataPolicyScreen = () => {
  const goBack = useGoBack();
  const [showAccessDataDialog, setShowAccessDataDialog] = useState(false);
  const [showDeleteDataDialog, setShowDeleteDataDialog] = useState(false);

  return (
    <Layout anglePosition="top">
      <TopBar left={<IconButton onClick={goBack} name="arrow-back" label="Back" />}>
        <Heading level="1" size="small">
          Settings
        </Heading>
      </TopBar>

      <Box flex center>
        <CircleIcon name="settings" label="Settings" size="large" />
      </Box>

      <Box flex column grow scroll gutter>
        <Heading level="1" align="left">
          Your data, your control
        </Heading>
        <Text>
          Under the General Data Protection Regulation, you have the right to access, rectify, port
          and erase your data.
        </Text>

        <Button onClick={() => setShowAccessDataDialog(true)}>Access Data</Button>
        <Button onClick={() => setShowDeleteDataDialog(true)}>Delete Data</Button>

        {/* Access Data Dialog */}
        <Dialog open={showAccessDataDialog} onRequestClose={() => setShowAccessDataDialog(false)}>
          <Form
            onSubmit={() => {}}
            render={() => (
              <>
                <Heading level="2">Access your data</Heading>
                <Text align="center">
                  Please enter your email address and we will send you the data we hold on you.
                </Text>
                <Input name="email" placeholder="Email address" required />
                <Box flex>
                  <Button flat onClick={() => setShowAccessDataDialog(false)}>
                    Cancel
                  </Button>
                  <Button flat secondary>
                    Access Data
                  </Button>
                </Box>
              </>
            )}
          />
        </Dialog>

        {/* Delete Data Dialog */}
        <Dialog open={showDeleteDataDialog} onRequestClose={() => setShowDeleteDataDialog(false)}>
          <Heading level="2">Delete all data</Heading>
          <Text align="center">
            All data retained about you will be deleted. This includes your balance.
          </Text>
          <Heading size="large">&euro; 13,99</Heading>
          <Box flex>
            <Button flat onClick={() => setShowDeleteDataDialog(false)}>
              Cancel
            </Button>
            <Button flat secondary>
              Delete Data
            </Button>
          </Box>
        </Dialog>
      </Box>
    </Layout>
  );
};

export default DataPolicyScreen;
