import React, { useState } from 'react';
import { useField, FieldProps } from '@blockle/form';

import './avatar-select.css';
import Avatar from 'components/Avatar';
import Dialog from 'components/Dialog';

// Avatars
import AvatarA from 'public/images/avatars/avatar-a.png';
import AvatarB from 'public/images/avatars/avatar-b.png';
import AvatarC from 'public/images/avatars/avatar-c.png';
import AvatarD from 'public/images/avatars/avatar-d.png';
import AvatarE from 'public/images/avatars/avatar-e.png';
import AvatarF from 'public/images/avatars/avatar-f.png';

type Value = string;
type Props = {
  value?: Value;
  required?: boolean;
} & FieldProps<Value>;

const imageUrls = [AvatarA, AvatarB, AvatarC, AvatarD, AvatarE, AvatarF].map(
  url => `${location.protocol}//${location.host}${url}`,
);
const defaultAvatarUrl = imageUrls[0];

const AvatarSelect = ({ name, required, value = defaultAvatarUrl }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { value: formValue, setValue, setTouched } = useField<Value>(name, {
    value,
    validate: (value: Value) => {
      if (required && !value) {
        return 'required';
      }

      return null;
    },
  });

  return (
    <>
      <Avatar
        size="large"
        url={formValue}
        icon="edit"
        onClick={() => {
          setTouched();
          setDialogOpen(true);
        }}
      />

      <Dialog open={dialogOpen} onRequestClose={() => setDialogOpen(false)}>
        <div className="AvatarSelect-dialog-items">
          {imageUrls.map(imageUrl => {
            const isSelected = imageUrl === formValue;

            return (
              <Avatar
                key={imageUrl}
                className={isSelected ? 'is-selected' : ''}
                url={imageUrl}
                icon={isSelected ? 'check' : undefined}
                onClick={() => {
                  setValue(imageUrl);
                  setDialogOpen(false);
                }}
              />
            );
          })}
        </div>
      </Dialog>
    </>
  );
};

export default AvatarSelect;
