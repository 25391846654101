import React from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import './dialog.css';
import { IconButton } from 'components/Button';
import { useLayer, useAnimationState } from 'hooks';

type Props = {
  children: React.ReactNode;
  onRequestClose: () => void;
  open: boolean;
};

const Dialog = ({ children, open, onRequestClose }: Props) => {
  const [state, close] = useAnimationState(!!open);
  const layer = useLayer();

  if (!state.open) {
    return null;
  }

  const dialog = (
    <div className="Dialog-container">
      <div
        className={cx('Dialog-overlay', state.leave && 'is-leave')}
        onClick={onRequestClose}
        onAnimationEnd={(event: React.AnimationEvent<HTMLDivElement>) => {
          if (event.animationName === 'Dialog-Overlay-Leave') {
            close();
          }
        }}
      />
      <div className={cx('Dialog', state.leave && 'is-leave')}>
        <div className="Dialog-Top">
          <IconButton name="close" label="Close" onClick={onRequestClose} />
        </div>
        <div className="Dialog-Content">{children}</div>
      </div>
    </div>
  );

  return createPortal(dialog, layer);
};

export default Dialog;
