import React from 'react';
import { useSelector } from 'react-redux';

import { Heading, Text } from 'components/Typography';
import { IconButton, Button } from 'components/Button';
import { TopBar, BottomBar } from 'components/Bar';
import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Logo from 'components/Logo';
import Layout from 'components/Layout';
import { useGoBack } from 'hooks';
import { AppState } from 'reducers';
import Checkbox from 'components/Form/Checkbox';
import Input from 'components/Form/Input';
import { Form } from '@blockle/form';

const ProfileScreen = () => {
  const goBack = useGoBack();
  const { avatarUrl = '', displayName } = useSelector((state: AppState) => state.userProfile);

  return (
    <Layout anglePosition="top">
      <TopBar
        left={<IconButton onClick={goBack} name="arrow-back" label="Back" />}
        right={<Logo size="small" />}
      >
        <Heading level="1" size="small">
          Profile
        </Heading>
      </TopBar>

      {/* TODO Classname */}
      <Form
        onSubmit={formData => console.log('submit', formData)}
        className="ProfileInfoScreen-form"
        render={() => (
          <>
            <Box grow scroll>
              <Box flex column center>
                <Avatar url={avatarUrl} />
                <Heading level="2" size="medium">
                  {displayName}
                </Heading>
              </Box>

              <Box gutter>
                <Input name="email" placeholder="Email address" />

                <Heading level="2" align="left" size="large">
                  Extra Information
                </Heading>

                <Text>We use this information to provide more accurate leaderboards.</Text>

                {/* TOOD Label need here */}
                <span>Gender</span>
                <Box flex>
                  <Box flex center grow>
                    <Checkbox name="male" /> &nbsp; Male
                  </Box>
                  <Box flex center grow>
                    <Checkbox name="female" /> &nbsp; Female
                  </Box>
                </Box>
              </Box>
            </Box>
            <BottomBar shadow>
              <Button type="submit" inline>
                Edit
              </Button>
            </BottomBar>
          </>
        )}
      />
    </Layout>
  );
};

export default ProfileScreen;
