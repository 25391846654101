import React, { StrictMode, Suspense, Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from '@blockle/router';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/browser';

import { persistor, store } from 'config/store';
import { history } from 'config/history';
import i18n from 'config/i18next';
import App from './App';
import ErrorScreen from 'screens/Error';

export default class AppShell extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: object) {
    // Send production build errors to sentry
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorScreen />;
    }

    return (
      <StrictMode>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router history={history}>
                <Suspense fallback="Loader">
                  <App />
                </Suspense>
              </Router>
            </PersistGate>
          </Provider>
        </I18nextProvider>
      </StrictMode>
    );
  }
}
