import React from 'react';

import { TopBar } from 'components/Bar';
import { IconButton, Button } from 'components/Button';
import Logo from 'components/Logo';
import { Heading } from 'components/Typography';
import Countdown from 'components/Countdown';
import Box from 'components/Box';
import Translate from 'components/Translate';
import PlayerCard from 'components/PlayerCard';
import Layout from 'components/Layout';

// REMOVE ME
const elDate = new Date(Date.now() + 12 * 60 * 60 * 1000);

interface Props {
  openDrawer(): void;
}

const DashboardScreen = ({ openDrawer }: Props) => (
  <Layout anglePosition="bottom">
    <TopBar
      left={<IconButton onClick={openDrawer} name="menu" label="Open menu" />}
      right={<Logo size="small" />}
    >
      <Heading level="1" size="small">
        <Translate i18nKey="DashboardPage.title">Welcome</Translate>
      </Heading>
    </TopBar>

    <Box grow scroll>
      <Countdown endDate={elDate} />
      <Box flex gutter center>
        <PlayerCard />
      </Box>
      <Box flex column center>
        <Button inline to="/leaderboards">
          <Translate i18nKey="DashboardPage.leaderboardButton">Leaderboard</Translate>
        </Button>
      </Box>
    </Box>
  </Layout>
);

export default DashboardScreen;
