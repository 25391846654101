import React from 'react';
import { useField, FieldProps } from '@blockle/form';
import Icon from 'components/Icon';

import './checkbox.css';

type Value = boolean;
type Props = {
  checked?: Value;
  required?: boolean;
  disabled?: boolean;
} & FieldProps<Value>;

const Checkbox = ({ name, checked, required, disabled = false }: Props) => {
  const { value, setValue, setTouched } = useField<Value>(name, {
    value: !!checked,
    validate: (value: Value) => {
      if (required && !value) {
        return 'required';
      }

      return null;
    },
  });
  const toggle = () => {
    if (disabled) {
      return;
    }

    setValue(!value);
  };

  return (
    <div
      className={`Checkbox ${value ? 'is-checked' : ''} ${disabled ? 'is-disabled' : ''}`}
      onClick={toggle}
      onBlur={setTouched}
      tabIndex={disabled ? undefined : 0}
      onKeyDown={event => {
        if (event.keyCode === 32) {
          // Prevent unwanted scrolling
          event.preventDefault();

          toggle();
        }
      }}
    >
      <Icon name="check" label="checkbox" size="small" />
    </div>
  );
};

export default Checkbox;
