import React from 'react';
import cx from 'classnames';

import './layout.css';

type Props = {
  children: React.ReactNode;
  anglePosition?: 'top' | 'quarter' | 'bottom';
};

const Layout = ({ children, anglePosition }: Props) => (
  <div className={cx('Layout', anglePosition && `angle-${anglePosition}`)}>{children}</div>
);

export default Layout;
