import React from 'react';

import Box from 'components/Box';
import Layout from 'components/Layout';
import { LiveryPlayer } from 'components/LiveryPlayer';

const BroadcastScreen = () => (
  <Layout >
    <Box grow>
      <LiveryPlayer />
    </Box>
  </Layout>
);

export default BroadcastScreen;
