import { ThunkAction } from 'redux-thunk';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

/**
 * provides loading and error information on dispatch
 *
 * @param {() => ThunkAction<Promise<any>, any, undefined, any>} action
 * @param {string[]} [deps=[]]
 * @returns { loading, error }
 */
export function useThunkAction(
  action: () => ThunkAction<Promise<any>, any, undefined, any>,
  deps: string[] = [],
) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(action());
      } catch (error) {
        setError(error);
      }
    };

    setError(false);
    setLoading(true);

    fetchData();

    setLoading(false);

    return () => setError(false);
  }, deps);

  return { loading, error };
}
