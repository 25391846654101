import React from 'react';

import './drawer.css';

type Props = {
  children: React.ReactNode;
  open?: boolean;
  onRequestClose: () => void;
};

const Drawer = ({ children, open, onRequestClose }: Props) => (
  <>
    <div className={`Drawer-back ${open ? 'is-open' : ''}`} onClick={onRequestClose} />
    <div className={`Drawer ${open ? 'is-open' : ''}`} onClick={onRequestClose}>
      {children}
    </div>
  </>
);

export default Drawer;
