import React from 'react';
import { Form } from '@blockle/form';

import { TopBar } from 'components/Bar';
import { IconButton, Button } from 'components/Button';
import { Heading, Text } from 'components/Typography';
import Box from 'components/Box';
import Input from 'components/Form/Input';
import CircleIcon from 'components/CircleIcon';
import { useGoBack } from 'hooks';
import Layout from 'components/Layout';

const CashOutScreen = () => {
  const goBack = useGoBack();

  return (
    <Layout anglePosition="top">
      <TopBar left={<IconButton onClick={goBack} name="arrow-back" label="Back" />}>
        <Heading level="1" size="small">
          My balance
        </Heading>
      </TopBar>

      <Box flex center>
        <CircleIcon name="cashout" label="Cash Out" size="large" />
      </Box>

      <Box flex column grow scroll gutter>
        <Heading level="3" size="large">
          0€
        </Heading>

        <Form
          onSubmit={() => {}}
          render={() => (
            <>
              <Input name="email" required placeholder="PayPal Email Address" />

              <Text align="center" weight="bold">
                Please verify that the email used above is a valid Paypal email account. You need a
                minimum balance  of 0.01€ to cashout.
              </Text>

              <Box flex center>
                <Button type="submit" disabled inline>
                  Cash out with PayPal
                </Button>
              </Box>
            </>
          )}
        />
      </Box>
    </Layout>
  );
};

export default CashOutScreen;
