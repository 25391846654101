import React from 'react';

import './logo.css';
import LogoSVG from 'public/icons/logo_shadow.svg';

type Props = {
  size: 'small' | 'medium' | 'large' | 'to-fit';
};

const Logo = ({ size = 'medium' }: Props) => (
  <LogoSVG title="Logo" className={`logo size-${size}`} />
);

export default Logo;
