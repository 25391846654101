import React, { useState } from 'react';
import { Form } from '@blockle/form';

import { TopBar } from 'components/Bar';
import { IconButton } from 'components/Button';
import { Heading, Text } from 'components/Typography';
import Box from 'components/Box';
import { useGoBack } from 'hooks';
import CircleIcon from 'components/CircleIcon';
import Layout from 'components/Layout';
import Switch from 'components/Form/Switch';
import Divider from 'components/Divider';

interface FormData {
  email: boolean;
  pushNotifications: boolean;
  phonenumber: boolean;
}

const SettingsScreen = () => {
  const goBack = useGoBack();

  const [sending, setSending] = useState(false);

  const submit = async (data: FormData) => {
    setSending(true);
    try {
      console.log('Settings');
    } catch (error) {
      alert(error.message);
    }
    setSending(false);
  };

  return (
    <Layout anglePosition="top">
      <TopBar left={<IconButton onClick={goBack} name="arrow-back" label="Back" />}>
        <Heading level="1" size="small">
          Settings
        </Heading>
      </TopBar>

      <Box flex center>
        <CircleIcon name="settings" label="Settings" size="large" />
      </Box>

      <Box flex column grow scroll gutter>
        <Form
          onSubmit={submit}
          className="SettingsScreen-form"
          render={() => (
            <>
              {/* Notifications */}
              <Heading level="2" align="left">
                Notifications
              </Heading>
              <Text>Receive notifications when the game is about to start</Text>

              <Text>Email</Text>
              <Switch name="email" checked />

              <Text>Push notifications</Text>
              <Switch
                name="pushNotifications"
                onChange={() => {
                  console.log('change');
                }}
              />

              <Divider />

              {/* User Data */}
              <Heading level="2" align="left">
                User Data
              </Heading>

              <Text>User data you currently share with app</Text>

              <Text>Phone number</Text>
              <Switch name="phonenumber" />

              <Text>Gender</Text>
              <Switch name="gender" />

              <Text>Location</Text>
              <Switch name="location" />
            </>
          )}
        />
      </Box>
    </Layout>
  );
};

export default SettingsScreen;
