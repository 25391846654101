import React from 'react';
import { Route } from '@blockle/router';

import BroadcastScreen from 'screens/Broadcast';
import CashOutScreen from 'screens/CashOut';
import DashboardScreen from 'screens/Dashboard';
import LeaderboardsScreen from 'screens/Leaderboards';
import NotFoundScreen from 'screens/NotFound';
import ProfileScreen from 'screens/Profile';
import RouteTransition from 'components/RouteTransition';
import SettingsScreen from 'screens/Settings';
import TextContentScreen from 'screens/TextContent';
import DataPolicyScreen from 'screens/DataPolicy';

type Props = {
  // requestDrawer?
  openDrawer(): void;
};

const ApplicationRoutes = ({ openDrawer }: Props) => (
  <>
    <Route path={['/', '/leaderboards']} exact>
      <DashboardScreen openDrawer={openDrawer} />

      <RouteTransition path="/leaderboards" effect="slideUp">
        <LeaderboardsScreen />
      </RouteTransition>
    </Route>

    {/* NOTE: delete later or combine with dashboard */}
    <Route path="/broadcast">
      <BroadcastScreen />
    </Route>

    <Route path="/profile">
      <ProfileScreen />
    </Route>

    <Route path="/cash-out">
      <CashOutScreen />
    </Route>

    <Route path="/settings">
      <SettingsScreen />
    </Route>

    <Route path="/data-policy">
      <DataPolicyScreen />
    </Route>

    <Route path="/how-to-play">
      <TextContentScreen contentKey="how-to-play" icon="help" />
    </Route>

    <Route path="/faq">
      <TextContentScreen contentKey="faq" icon="faq" />
    </Route>

    <Route path="/help">
      <TextContentScreen contentKey="help" icon="text-balloon" />
    </Route>

    {/* 404 */}
    <Route noMatch>
      <NotFoundScreen />
    </Route>
  </>
);

export default React.memo(ApplicationRoutes);
