import { userSelectors } from '@playtwo/public';
import Drawer from 'components/Drawer';
import Paper from 'components/Paper';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import DrawerContent from 'screens/Drawer';
import ProfileInfoScreen from 'screens/RequestProfileInfo';
import TermsScreen from 'screens/Terms';
import ApplicationRoutes from './ApplicationRoutes';

type Props = {
  open: boolean;
};

const ApplicationScreen = ({ open = false }: Props) => {
  const [drawerOpen, setDrawer] = useState(false);
  const missingAccountInfo = useSelector((state: AppState) =>
    userSelectors.hasProfileActionPending(state, 'SET_AVATARURL'),
  );
  const termsUpdated = useSelector((state: AppState) =>
    userSelectors.hasActionPending(state, 'AGREE_TO_TERMS'),
  );

  return (
    <Paper open={open} fit effect="fade">
      <Drawer open={drawerOpen} onRequestClose={() => setDrawer(false)}>
        <DrawerContent />
      </Drawer>

      {!missingAccountInfo && !termsUpdated && (
        <ApplicationRoutes openDrawer={() => setDrawer(true)} />
      )}

      <ProfileInfoScreen open={missingAccountInfo} />
      <TermsScreen open={termsUpdated} />
    </Paper>
  );
};

export default ApplicationScreen;
