import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from 'components/Dialog';
import { Heading, Text } from 'components/Typography';
import { Button } from 'components/Button';
import { socketSelectors, socketActions } from '@playtwo/core';

const SocketStatusDialog = () => {
  const dispatch = useDispatch();
  const socketState = useSelector(socketSelectors.getState);

  if (socketState === 'FAILED_TO_CONNECT') {
    return (
      <Dialog open onRequestClose={() => {}}>
        <Heading level="2">Connection error</Heading>
        <Text align="center">Failed to connect</Text>

        <Button onClick={() => dispatch(socketActions.reconnect())}>Retry</Button>
      </Dialog>
    );
  }

  if (socketState === 'CLOSED') {
    return (
      <Dialog open onRequestClose={() => {}}>
        <Heading level="2">Connection closed</Heading>

        <Button onClick={() => dispatch({ type: '@@socket/RECONNECT' })}>Retry</Button>
      </Dialog>
    );
  }

  if (socketState === 'CONNECTING') {
    return (
      <Dialog open onRequestClose={() => {}}>
        <Heading level="2">Reconnecting</Heading>
      </Dialog>
    );
  }

  return null;
};

export default SocketStatusDialog;
