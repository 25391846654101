import React from 'react';

import { Heading } from 'components/Typography';
import { IconButton } from 'components/Button';
import { TopBar } from 'components/Bar';
import Tabs, { Tab } from 'components/Tabs';
import LeaderboardScreen from './LeaderboardScreen';
import { useGoBack } from 'hooks';
import Layout from 'components/Layout';

const LeaderboardsScreen = () => {
  const goBack = useGoBack();

  return (
    <Layout anglePosition="quarter">
      <TopBar right={<IconButton name="close" label="Close" onClick={goBack} />}>
        <Heading level="1" size="small">
          Leaderboards
        </Heading>
      </TopBar>

      <Tabs>
        <Tab label="General">
          <LeaderboardScreen name="general" />
        </Tab>
        <Tab label="Country">
          <LeaderboardScreen name="country" />
        </Tab>
        <Tab label="Age">
          <LeaderboardScreen name="age" />
        </Tab>
        <Tab label="Gender">
          <LeaderboardScreen name="gender" />
        </Tab>
      </Tabs>
    </Layout>
  );
};

export default LeaderboardsScreen;
