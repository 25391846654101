import React, { Component } from 'react';

import './ripple.css';

interface Props {
  children?: React.ReactNode;
  renderAs: string;
  className?: string;
  [key: string]: any;
}

export default class Ripple extends Component<Props> {
  static defaultProps = {
    className: '',
  };

  ref = React.createRef<HTMLElement>();
  isTouch = false;
  raf?: number;

  componentWillUnmount() {
    if (this.raf) {
      cancelAnimationFrame(this.raf);
    }
  }

  onMouseDown = (event: React.MouseEvent) => {
    // React onMouseDown also triggers on touch, so ignore mouseDown triggered
    // after touchStart
    if (this.isTouch) {
      return;
    }

    const { clientX, clientY } = event;

    this.createRipple(clientX, clientY);
  };

  onTouchStart = (event: React.TouchEvent) => {
    const { clientX, clientY } = event.targetTouches[0];

    this.isTouch = true;
    this.createRipple(clientX, clientY);
  };

  createRipple(clientX: number, clientY: number) {
    const { current } = this.ref;

    if (!current) {
      return;
    }

    const rect = current.getBoundingClientRect();
    const ripple = document.createElement('div');

    ripple.className = 'ripple-effect';
    ripple.style.top = `${clientY - rect.top - 50 / 2}px`;
    ripple.style.left = `${clientX - rect.left - 50 / 2}px`;

    ripple.addEventListener('transitionend', this.removeRipple);

    current.appendChild(ripple);

    this.raf = requestAnimationFrame(() => {
      this.raf = requestAnimationFrame(() => {
        ripple.classList.add('is-animating');
      });
    });
  }

  removeRipple = (event: TransitionEvent) => {
    const ripple = event.currentTarget as HTMLDivElement;

    if (ripple.parentNode) {
      ripple.parentNode.removeChild(ripple);
    }
  };

  render() {
    const { children, renderAs, className, ...props } = this.props;

    return React.createElement(
      renderAs,
      {
        ref: this.ref,
        ...props,
        className: `ripple ${className}`,
        onMouseDown: this.onMouseDown,
        onTouchStart: this.onTouchStart,
      },
      children,
    );
  }
}
