import React from 'react';

import './icon.css';
import ArrowBack from 'public/icons/arrow-back.svg';
import ArrowLeft from 'public/icons/arrow-left.svg';
import Cashout from 'public/icons/cashout.svg';
import Check from 'public/icons/check.svg';
import Close from 'public/icons/close.svg';
import CoinsStack from 'public/icons/coins-stack.svg';
import Data from 'public/icons/data.svg';
import Dropdown from 'public/icons/dropdown.svg';
import Edit from 'public/icons/edit.svg';
import Facebook from 'public/icons/facebook.svg';
import Faq from 'public/icons/faq.svg';
import Help from 'public/icons/help.svg';
import Instagram from 'public/icons/instagram.svg';
import Logout from 'public/icons/logout.svg';
import Menu from 'public/icons/menu.svg';
import Profile from 'public/icons/profile.svg';
import Settings from 'public/icons/settings.svg';
import TextBalloon from 'public/icons/text-balloon.svg';
import Trophy from 'public/icons/trophy.svg';
import Twitter from 'public/icons/twitter.svg';
import Warning from 'public/icons/warning.svg';

const ICON_MAP = {
  'arrow-back': ArrowBack,
  'arrow-left': ArrowLeft,
  'coins-stack': CoinsStack,
  'text-balloon': TextBalloon,
  cashout: Cashout,
  check: Check,
  close: Close,
  data: Data,
  dropdown: Dropdown,
  edit: Edit,
  facebook: Facebook,
  faq: Faq,
  help: Help,
  instagram: Instagram,
  logout: Logout,
  menu: Menu,
  profile: Profile,
  settings: Settings,
  trophy: Trophy,
  twitter: Twitter,
  warning: Warning,
};

export type IconNames = keyof typeof ICON_MAP;

type Props = {
  label: string;
  name: IconNames;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  color?: 'light' | 'dark' | 'accent';
};

const Icon = ({ label, name, size = 'medium', color = 'dark' }: Props) => (
  <span className={`Icon size-${size} color-${color}`} title={label}>
    {React.createElement(ICON_MAP[name], {
      className: 'icon-svg',
      'data-testid': 'icon-svg',
    })}
  </span>
);

export default Icon;
