import React from 'react';

import './top-bar.css';

type Props = {
  children: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
};

const TopBar = ({ children, left, right }: Props) => (
  <header className="TopBar">
    {left && <div className="TopBar-left">{left}</div>}

    <div className="TopBar-center">{children}</div>

    {right && <div className="TopBar-right">{right}</div>}
  </header>
);

export default TopBar;
