import { useEffect } from 'react';

type Props = {
  target: string;
  when: boolean;
  duration?: number;
};

const RemoveElement = ({ target, when, duration = 400 }: Props) => {
  useEffect(() => {
    if (!when) {
      return;
    }

    const node = document.querySelector<HTMLDivElement>(target);

    if (!node) {
      if (__DEV__) {
        console.error(`Failed to find target "${target}"`);
      }

      return;
    }

    node.style.transition = `opacity ${duration}ms ease-out`;
    node.style.opacity = '0';

    setTimeout(() => {
      if (node && node.parentNode) {
        node.parentNode.removeChild(node);
      }
    }, duration + 200);
  }, [when]);

  return null;
};

export default RemoveElement;
